export const wordCloud = [
  { text:"HTML", value:1000},
  { text:"CSS", value:1000},
  { text:"SASS", value:1000},
  { text:"PHP", value:1000},
  { text:"WORDPRESS", value:1000},
  { text:"JAVASCRIPT", value:1000},
  { text:"JQUERY", value:1000},
  { text:"BOOTSTRAP", value:1000},
  { text:"GIT", value:1000},
  { text:"MYSQL", value:1000},
  { text:"MSSQL", value:1000},
  { text:"MARIADB", value:1000},
  { text:"ADOBE CREATIVE SUITE", value:1000},
  { text:"RESPONSIVE", value:1000},
  { text:"UX DESIGN", value:1000},
  { text:"SLACK API", value:1000},
  { text:"TWILIO API", value:1000},
  { text:"REST", value:1000},
  { text:"API INTEGRATIONS", value:1000},
  { text:"SEO", value:1000},
  { text:"ANALYTICS", value:1000},
  { text:"GRAPHQL", value:1000},
  { text:"NODEJS", value:1000},
  { text:"REACTJS", value:1000},
  { text:"TYPESCRIPT", value:1000},
  { text:"COBOL", value:1000},
  { text:"JCL", value:1000},
  { text:"DB2", value:1000}
];